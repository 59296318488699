<template>
  <span class="base-banner-avatars">
    <img v-for="item in items" :src="item" alt="Аватар">
  </span>
</template>

<script setup>
import Image1 from '~/assets/img/ava.png'
import Image2 from '~/assets/img/ava1.png'
import Image3 from '~/assets/img/ava2.png'
import Image4 from '~/assets/img/ava3.png'
import Image5 from '~/assets/img/ava4.png'
import Image6 from '~/assets/img/ava5.png'
import Image7 from '~/assets/img/ava6.png'
import Image8 from '~/assets/img/ava7.png'

const images = useState(() => [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8])

const items = useState(() => {
  return Array.from({ length: 4 }).map(
    () => images.value.splice(
      Math.floor(Math.random() * images.value.length),
      1
    )[0]
  )
})
</script>

<style scoped lang="scss">
.base-banner-avatars {
  display: inline-flex;
  align-items: center;
  flex-direction: row-reverse;
  user-select: none;
  margin: -3px 0;
  @media (max-width: 920px) {
    vertical-align: middle;
  }
  > img {
    width: 31px;
    height: 31px;
    border-radius: 50%;
    border: 3px solid var(--color-bg-secondary);
    box-sizing: content-box;
    object-fit: cover;
    &:not(:first-child) {
      margin-right: -8px;
      @media (max-width: 920px) {
        margin-right: -12px;
      }
    }
    @media (max-width: 920px) {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
