<template>
  <FilterControl :name="$attrs.name ?? 'Стоимость занятия'">
    <FilterRange
      ref="range"
      v-bind="props"
      v-model:from="modelFrom"
      v-model:to="modelTo"
      @change="emit('change', $event)"
      @submit="emit('submit')"
    />
  </FilterControl>
</template>

<script setup>
const props = defineProps(['from', 'to', 'min', 'max'])
const emit = defineEmits(['update:from', 'update:to', 'change', 'submit'])

const modelFrom = computed({
  get: () => props.from,
  set: value => emit('update:from', value)
})
const modelTo = computed({
  get: () => props.to,
  set: value => emit('update:to', value)
})

const range = ref()
defineExpose({ updateValue: () => range.value?.sliderUpdate() })
</script>

<style scoped lang="scss">

</style>
