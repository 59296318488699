<script setup>
const props = defineProps({
  faq: Array
})

const activeIndex = ref(0);

const handleClick = (index) => {
  activeIndex.value = activeIndex.value === index ? null : index;
}
const isActive = (index) => {
  return activeIndex.value === index;
}

</script>

<template>
  <div class="faq horiz-container">
    <h2>Часто задаваемые вопросы</h2>
    <div class="faq-container">
      <div @click="handleClick(index)" v-for="(item, index) in faq" :key="index"
           :class="['faq-item', { active: isActive(index) }]">
        <div class="faq-item-title">
          <p class="question">{{ item.title }}</p>
          <svg width="20" height="20" class="chevron-down"
               xmlns="http://www.w3.org/2000/svg"
               xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 360 360" style="enable-background:new 0 0 360 360;" xml:space="preserve">
<path d="M180,282.5c-6.6,0-13.1-2.5-18.1-7.5L8.1,121.2c-10-10-10-26.2,0-36.2s26.2-10,36.2,0L180,220.6L315.6,85
	c10-10,26.2-10,36.2,0s10,26.2,0,36.2L198.1,275C193.1,280,186.6,282.5,180,282.5z"/>
</svg>
        </div>
        <div class="description">
          <ContentRenderer :content="item.content"/>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.faq {
  padding: 80px 60px;

  h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 48px;
    color: #000000;
    margin-bottom: 50px;
  }

  &-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 30px;
    gap: 8px;
    border: 1px solid rgba(60, 60, 67, 0.3);
    border-radius: 30px;
    transition: all 0.5s;
    cursor: pointer;

    &.active {
      background: #F1F9E9;
      height: auto;
    }

    & .description {
      display: none;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #333333;
      transition: display .3s;

    }

    &.active .description {
      display: block;
    }

    &.active svg {
      transform: rotate(180deg);
    }

    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      svg {
        transition: transform 0.5s;
        cursor: pointer;
        z-index: 0;
      }
    }

    & .question {
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 28px;
      color: #333333;
    }
  }
}
</style>
